import {
  IconBrandBluesky,
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandTwitch,
  IconCat,
  IconShoppingCart,
  IconPaw,
  IconWoman,
} from '@tabler/icons-react'

const links = [
  {
    title: 'Furry Trash Apparel',
    url: 'https://furrytrashco.com',
    icon: IconShoppingCart,
  },
  {
    title: "Khora's Reference Sheet ",
    url: '/khora-caracal-reference sheet-by-gummaye.png',
    icon: IconCat,
  },
  {
    title: 'Bluesky',
    url: 'https://bsky.app/profile/khora.lol',
    icon: IconBrandBluesky,
  },
  {
    title: 'Instagram',
    url: 'https://instagram.com/khoracal',
    icon: IconBrandInstagram,
  },
  {
    title: 'Furtrack',
    url: 'https://www.furtrack.com/user/khora/fursuiting',
    icon: IconPaw,
  },
  {
    title: 'Tiktok',
    url: 'https://www.tiktok.com/@khoracal',
    icon: IconBrandTiktok,
  },
  {
    title: 'Twitch',
    url: 'https://www.twitch.tv/khoracal',
    icon: IconBrandTwitch,
  },
  {
    title: 'Looking for my normie links? Click here!',
    url: 'https://explodedsoda.me',
    icon: IconWoman,
  },
]

export default links
