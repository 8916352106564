const GA_MEASUREMENT_ID = 'G-YJSVELJCRZ'

// Initialize Google Analytics
export const initGA = () => {
  window.dataLayer = window.dataLayer || []
  function gtag() {
    window.dataLayer.push(arguments)
  }
  window.gtag = gtag

  gtag('js', new Date())
  gtag('config', GA_MEASUREMENT_ID, { send_page_view: false })
}

// Log a pageview
export const logPageView = () => {
  window.gtag?.('event', 'page_view', {
    page_path: window.location.pathname + window.location.search,
  })
}

// Log an event
export const logEvent = (category, action, label) => {
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    send_to: GA_MEASUREMENT_ID,
  })
}
